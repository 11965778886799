<template>
  <div>
    <InventoryTable
      :title="tLabel('Gate-out')"
      :headers="headers"
      :defaultFilters="defaultFilters"
      :defaultSort="defaultSort"
      :excludeButtons="{ unitNumber: true, sysTypes: true, tipoUnit: true, voyages: true }"
    >
      <template v-slot:filterButtons="{ filters }">
        <v-col cols="2">
          <v-text-field :label="tLabel('Unit number')" v-model="filters['B.object_codeLike']" @input="(val) => (filters['B.object_codeLike'] = val ? val.toUpperCase() : null)" />
        </v-col>
        <v-col cols="2">
          <v-select :label="tLabel('SysType')" v-model="filters.sysTypeIn" :items="sysTypes" multiple />
        </v-col>
        <v-col cols="2">
          <v-autocomplete clearable :label="tLabel('Tipo Unit')" v-model="filters.type_code" :items="unitTypes" item-text="text" item-value="value"> </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-text-field :label="tLabel('Voyage In/Out')" v-model="filters.voyagesLike" @input="(val) => (filters.voyagesLike = val ? val.toUpperCase() : null)" />
        </v-col>
        <v-col cols="2">
          <v-text-field :label="tLabel('Position')" v-model="filters['B.position_codeLike']" @input="(val) => (filters['B.position_codeLike'] = val ? val.toUpperCase() : null)" />
        </v-col>
      </template>
      <template v-slot:item-menu="{ item }">
        <v-list>
          <v-list-item @click="prepareGateOut(item)" :disabled="!['E', 'Y'].includes(item.status)">
            <v-list-item-title class="text-uppercase">{{ tLabel("Gate-out") }}</v-list-item-title>
            <v-list-item-action>
              <v-icon color="secondary" medium>mdi-logout-variant</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </InventoryTable>
    <v-dialog v-model="dialogGateOutVisible" fullscreen scrollable persistent>
      <GateOut
        :gateSubmitReqObj="gateSubmitReqObj"
        :listTruckPlatesAssociated="listTruckPlatesAssociated"
        :handlingOutTruck="handlingOutTruck"
        :listTruckPlates="listTruckPlates"
      />
    </v-dialog>
  </div>
</template>
<script>
import InventoryTable from "@/components/yard/InventoryTable";
import GateOut from "@/components/yard/GateOut";
export default {
  name: "GateOutVisits",
  data() {
    return {
      item: null,
      defaultFilters: { visitStatusIn: ["E", "Y"], sysTypeIn: ["UNT", "GOO"] },
      dialogGateOutVisible: false,
      pickUpCarrier: null,
      gateSubmitReqObj: {},
      listTruckPlates: [],
      listTruckPlatesAssociated: [],
      handlingOutTruck: {},
      defaultSort: { field: "data_in", desc: false },
      unitTypes: [],
      roroTypes: [],
      sysTypes: null,
      headers: [
        { text: `${this.tLabel("Type")}`, value: "sysType" },
        { text: `${this.tLabel("Unit")}`, value: "objectCode" },
        //{ text: `${this.tLabel('Unit')}`, value: "units"},
        { text: `${this.tLabel("Operator")}`, value: "owner" },
        { text: `${this.tLabel("Unit Type")}`, value: "typeCode", sortable: false },
        { text: `${this.tLabel("ISO Size Type")}`, value: "isoSizeType" },
        { text: `${this.tLabel("Items")}`, value: "qty" },
        { text: `${this.tLabel("WGT")}`, value: "grossweight" },
        { text: `${this.tLabel("Customs Status")}`, value: "customsStatus" },
        { text: `${this.tLabel("Full/Empty")}`, value: "fullEmpty" },
        { text: `${this.tLabel("UNDG")}`, value: "dangerous" },
        { text: `${this.tLabel("Position")}`, value: "positionCode" },
        { text: `${this.tLabel("Order")}`, value: "hinOrderCode" },
        { text: `${this.tLabel("In")}`, value: "hinTransportType" },
        { text: `${this.tLabel("Voyage In")}`, value: "hinVoyageCode" },
        { text: `${this.tLabel("Date In")}`, value: "dataIn", formatValue: "formatDateTimeFromDate" },
        { text: `${this.tLabel("Out")}`, value: "houtTransportType" },
        { text: `${this.tLabel("Voyage Out")}`, value: "houtVoyageCode" },
        { text: `${this.tLabel("Planned Date Out")}`, value: "houtPlannedDate", formatValue: "formatDateTimeFromDate" },
      ],
    };
  },
  async mounted() {
    this.loadCombo();
  },
  async created() {
    this.$eventBus.$on("gateOutDone", (gateOutDone) => {
      console.log("gateOutDone", gateOutDone);
      this.dialogGateOutVisible = false;
      if (gateOutDone === true) {
        this.$emit("refreshTable");
      }
    });
  },
  methods: {
    async prepareGateOut(item) {
      console.log("prepareGateOut item", item);
      const isRoro = item.sysType == "RRO";
      let truckLicensePlate = null;
      let truckOwnerCompanyId = null;
      let idTruck = null;
      let damages = [];
      let itineraryVisit = {};
      if (isRoro) {
        truckLicensePlate = item.objectCode;
        truckOwnerCompanyId = item.ownerCompanyId;
      } else {
        const prepareGateOutResp = await this.$api.yardData.prepareGateOut(item.id);
        this.listTruckPlates = prepareGateOutResp.carriers;
        this.pickUpCarrier = prepareGateOutResp.pickUpCarrier;
        this.listTruckPlatesAssociated = prepareGateOutResp.listPickUpCarrier;
        console.log("listTruckPlatesAssociated:", this.listTruckPlatesAssociated);
        console.log("Pick Carrier:", this.pickUpCarrier);
        if (this.pickUpCarrier !== null) {
          truckLicensePlate = this.pickUpCarrier.licensePlate;
          console.log("Truck License Plate:", truckLicensePlate);
          idTruck = this.pickUpCarrier.id;
          console.log("id Truck:", idTruck);
          truckOwnerCompanyId = this.pickUpCarrier.ownerCompanyId;
          console.log("Truck Owner:", truckOwnerCompanyId);
        }
        damages = prepareGateOutResp.damages;
        itineraryVisit = await this.$api.yardData.getItineraryByVisitId(item.id);
      }
      this.gateSubmitReqObj = {
        item: item,
        units: [
          {
            visitId: item.id,
            sysType: item.sysType,
            objectCode: item.objectCode,
            quantity: item.qty,
            initialQuantity: item.qty,
            countType: item.countType,
            damages: damages,
          },
        ],
        handlingOutTruckLicensePlate: truckLicensePlate,
        handlingOutTruckOwnerCompanyId: truckOwnerCompanyId,
        handlingOutIdTruck: idTruck,
        isRoro: isRoro,
        itineraryVisit: itineraryVisit && itineraryVisit !== null ? itineraryVisit : {},
      };

      if (this.gateSubmitReqObj.handlingOutTruckLicensePlate !== null) {
        this.handlingOutTruck = this.listTruckPlates.find((p) => p.licensePlate === this.pickUpCarrier.licensePlate);
      }
      console.log("prepareGateOut gateSubmitReqObj", this.gateSubmitReqObj);
      this.dialogGateOutVisible = true;
    },
    async loadCombo() {
      this.sysTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "lookups/sysTypes");
      this.unitTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "unit-types/comboList");
      let goodsType = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "goods-types/comboList");
      this.unitTypes.push.apply(this.unitTypes, goodsType);
      this.roroType = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "rorotypes/comboList");
      this.unitTypes.push.apply(this.unitTypes, this.roroType);
    },
  },
  components: { InventoryTable, GateOut },
};
</script>
